import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Redirect, Route, Switch } from "react-router-dom";
import { authService } from "../../services/authService";
import About from "../About/About";
import Book from "../Book/Book";
import Details from "../Details/Details";
import Contactus from "../Contactus/Contactus";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import Search from "../Search/Search";
import Services from "../Services/Services";
import Footer from "./Footer";
import Header from "./Header";
import "./Layout.scss";
import MarketingAgreement from "./MarketingAgreement";
import { checkForArabic } from "../../helpers/arabicLangValidator";
import {isPureString} from "../../helpers/urls";
import {isPordalProperty} from "../../helpers/propertyFrom";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactVisible: false,
      selectedTag: null,
      paramObj:{title:document?.title,city:'dubai',tags:undefined},
    };

    const params = new URLSearchParams(this.props.location.search);
    const admin = params.get("admin");
    if (admin) {
      authService.setAdmin();
    }
  }

  componentDidMount() {
    window.addEventListener("openContact", this.showContact.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("openContact", this.showContact.bind(this));
  }

  showContact() {
    this.setContactVisible(true);
  }

  setContactVisible(value) {
    this.setState({ contactVisible: value });
  }

  onCityUpdate(tag) {
    this.setState({ selectedTag: tag });
  }

  getDetailTitle(obj) {
    this.setState({
      paramObj: {
        ...this.state.paramObj,
        ...obj,
        city:
          ["ABU","abudhabi","abu-dhabi"].includes( obj?.city)
            ? "abu-dhabi"
            : 'dubai',
      },
    });
  }

  render() {
    return (
      <div className="container" dir={checkForArabic("rtl", "ltr")}>
        <Header
          {...this.props}
          setContactVisible={this.setContactVisible.bind(this)}
          paramObj={this.state.paramObj}
        />
        <div className="main">
          <Switch>
            <Route exact path="/tags/:city/:area?" render={({ match }) => (
              <Redirect to={`/${match.params.city}/${match.params.area || ''}`} />
            )} />
            <Route exact path="/:city/tags/:area?" render={({ match }) => (
              <Redirect to={`/${match.params.city}/${match.params.area || ''}`} />
            )} />
            <Route
              exact
              path="/about"
              render={(props) => (
                <About {...props} onCityUpdate={this.onCityUpdate.bind(this)} />
              )}
            />
            <Route
              exact
              path="/services"
              render={(props) => (
                <Services
                  {...props}
                  onCityUpdate={this.onCityUpdate.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/privacy-policy"
              render={(props) => (
                <PrivacyPolicy
                  {...props}
                  onCityUpdate={this.onCityUpdate.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/contact"
              render={(props) => (
                <Contactus
                  {...props}
                  onCityUpdate={this.onCityUpdate.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/book/:propertyId"
              render={(props) => (
                <Book {...props} onCityUpdate={this.onCityUpdate.bind(this)} 
                  getDetailTitle={this.getDetailTitle.bind(this)}
                />
              )}
            />
            <Route
							exact
							path="/:city/:paramValue"
							render={(props) => {
								const {paramValue } = props.match.params;
                const shouldRenderDetails = paramValue && (isPordalProperty(paramValue) || !isPureString(paramValue));

                const CommonProps = {
                  ...props,
                  getDetailTitle: this.getDetailTitle.bind(this),
                  onCityUpdate: this.onCityUpdate.bind(this),
                };
                return shouldRenderDetails ? <Details {...CommonProps} /> : <Search {...CommonProps} />;
							}}
						/>
            <Route
              exact
              path="/:city"
              render={(props) => (
                <Search
                  getDetailTitle={this.getDetailTitle.bind(this)}
                  {...props}
                  onCityUpdate={this.onCityUpdate.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/:city/:tags/:propertyId"
              render={(props) => (
                <Details
                  {...props}
                  getDetailTitle={this.getDetailTitle.bind(this)}
                  onCityUpdate={this.onCityUpdate.bind(this)}
                />
              )}
            />
            <Redirect to="/" />
          </Switch>
        <Footer selectedTag={this.state.selectedTag} />
        </div>
        <MarketingAgreement />
      </div>
    );
  }
}

export default withTranslation("common")(Layout);
