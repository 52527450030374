import { withTranslation } from "react-i18next";
import { checkForArabic } from "../../helpers/arabicLangValidator";
import { getTagsIndex } from "../../helpers/urls";
import settings, {checkAreaMatchesForDescription} from "../../settings";
import FAQ from "./FAQ";
import { useParams } from 'react-router-dom'
import {useMemo} from "react";

const ShortDescription = ({ tags, city, t, renderBottomtagLinks, }) => {
	const { paramValue, city:cityParam } = useParams()

	const matchedDescription = useMemo(() => {
		if (!paramValue) return cityParam;
		const match = checkAreaMatchesForDescription(paramValue, cityParam);
		return paramValue === match ? match : false;
	}, [paramValue, cityParam]);
	  
	const renderHtmlContent = (key) => {
		return <p className="rental-guide-detail" dangerouslySetInnerHTML={{ __html: t(`${matchedDescription}${key}`) }}></p>;
	};

	return (
		<div className={`description-list ${checkForArabic("arabic-description-list")}`}>
			<div className="rental-guide">
				{matchedDescription && (
					<>
						<h2>{t(`${matchedDescription}rentalguide`)} </h2>
						{renderHtmlContent("rentaldesc")}
						{((paramValue && paramValue === cityParam) || !paramValue) && (
							<>
								<h2>{t(`cityguide`)} </h2>
								{renderHtmlContent("CityGuide")}
							</>
						)}
					</>
				)}
				<h2>{t(`cityarea`)}</h2>
				{renderBottomtagLinks(settings[getTagsIndex(city.urlName)])}

				{matchedDescription && (
					<>
						<h2>{t(`faqs`)}</h2>
						<FAQ city={city?.urlName} tags={tags} t={t} />
					</>
				)}
			</div>
		</div>
	);
};

export default withTranslation("short_desc")(ShortDescription);
