export const getReplacedTag = (tag) => {
	const tagSlug = tag.tag.trim();
	const newUrl = tagSlug.toLowerCase();
	const stringWithoutSpaces = newUrl.replace(/\s*\/\s*/g, "/");
	// eslint-disable-next-line
	const replacedString = stringWithoutSpaces.replace(/[\/\s']/g, "-");
	return replacedString;
};

export const generateSearchLink = (
  city,
  startDate = null,
  endDate = null,
  guests = 1,
  sort = "default",
  page = 1,
  tags,
  bedrooms,
  searchText
) => {
  let url = `/${city}`;

  // Helper function to add parameters
  const addParam = (key, value) => {
    if (url.includes('?')) {
      url += `&${key}=${value}`;
    } else {
      url += `?${key}=${value}`;
    }
  };

  if (tags?.length) {
    url += '/' + tags.map(tag => getReplacedTag(tag)).join(',');
  }

  if (guests > 1) {
    addParam('guests', guests);
  }

  if (startDate) {
    addParam('from', startDate.format("YYYY-MM-DD"));
  }

  if (endDate) {
    addParam('to', endDate.format("YYYY-MM-DD"));
  }

  if (sort !== "search:default") {
    addParam('sort', sort);
  }

  if (page > 1) {
    addParam('page', page);
  }

  if (bedrooms?.length) {
    addParam('bedrooms', bedrooms.map(bedroom => bedroom.name).join(','));
  }

  if (searchText?.length) {
    addParam('searchText', searchText);
  }

  return url;
};

export const getTagsIndex = (city) => {
    let tagsIndex;
    switch (city) {
        case 'abu-dhabi':
            tagsIndex = 'tagsAbuDhabi';
            break;

        case 'stpetersburg':
            tagsIndex = 'tagsStpetersburg';
            break;

        case 'dubai':
        default:
            tagsIndex = 'tagsDubai';
    }

    return tagsIndex;
};

export const isPureString = (value) => {
  if (typeof value !== "string") return typeof value !== "number";
  const trimmedValue = value.trim();
  return isNaN(parseFloat(trimmedValue)) || trimmedValue === "";
};